@import './fonts.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f8;
}
a {
  text-decoration: none;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1 0 auto;
  // padding: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/////////////////////////////////////////////////////////////////////////

.sentinel {
  font-family: Sentinel, Roboto, serif;
}

.grecaptcha-badge {
  visibility: hidden;
}

.print {
  display: block;
}

@media print {
  .dont-print {
    display: none;
  }
}
