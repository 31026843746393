// Nudista
@font-face {
  font-family: 'Nudista';
  src: local('Nudista'), url(../../fonts/Nudista_Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Nudista';
  font-weight: 100;
  src: local('Nudista'), url(../../fonts/Nudista_Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Nudista';
  font-weight: 300;
  src: local('Nudista'), url(../../fonts/Nudista_Light.otf) format('opentype');
}
@font-face {
  font-family: 'Nudista';
  font-weight: 700;
  src: local('Nudista'),
    url(../../fonts/Nudista_Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'Nudista';
  font-weight: 800;
  src: local('Nudista'), url(../../fonts/Nudista_Bold.otf) format('opentype');
}

// Sentinel
@font-face {
  font-family: 'Sentinel';
  src: local('Sentinel'),
    url(../../fonts/Sentinel-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Sentinel';
  font-weight: 300;
  src: local('Sentinel'), url(../../fonts/Sentinel-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Sentinel';
  font-weight: 400;
  src: local('Sentinel'), url(../../fonts/Sentinel-Book.otf) format('opentype');
}
@font-face {
  font-family: 'Sentinel';
  font-weight: 700;
  src: local('Sentinel'),
    url(../../fonts/Sentinel-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'Sentinel';
  font-weight: 800;
  src: local('Sentinel'), url(../../fonts/Sentinel-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Sentinel';
  font-weight: 900;
  src: local('Sentinel'), url(../../fonts/Sentinel-Black.otf) format('opentype');
}
